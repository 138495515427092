<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper
          :items="categorias"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              Categorías
              <CButton
                class="btn btn-success"
                size="sm"
                @click="addCategoria()"
              >
                Añadir categoria
              </CButton>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/TablaCategorias";
import {getCategoriasData} from '../../utils/utils';
import "core-js/stable";
import "regenerator-runtime/runtime";

export default {
  name: "CategoriaTable",
  components: { CTableWrapper },
  data() {
    return {
      temp: 0,
      tempC: 0,
      categorias: [],
    };
  },
  created() {
    this.getCategorias();
  },
  methods: {
    addCategoria() {
      this.$router.push("/categoria/crearCategoria/");
    },
    recibirDatos() {
      this.getCategorias();
    },
    /**
     * Función en la que se obtienen las categorías y que también
     * llama al metodo recibirDatos para cargarlos.
     * 
     * @returns {object[]} Las categorías obtenidas.
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categorias;
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = result;
        this.tempC++;
      });
      return this.categorias;
    },
  },
};
</script>