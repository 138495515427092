<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :fields="fields"
        :items="items"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Categorías por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #id="{ item }">
          <td>
            {{ item.category_id }}
          </td>
        </template>

        <template #category_name="{ item }">
          <td>
            {{ item.category_name }}
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            <CButton
              color="success"
              shape="pill"
              size="sm"
              style="margin-right: 3px;"
              @click="editCategory(item.category_id)"
            >
              Editar
            </CButton>

            <CButton
              color="danger"
              shape="pill"
              size="sm"
              @click="botonEliminar(item)"
            >
              Eliminar
            </CButton>

            <CModal
              id="modalElim"
              title="Eliminar categoria"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar la categoría "
                {{ category.category_name }}" ? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="deleteCategory(category.category_id)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

export default {
  name: "Table",
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "id",
            label: "Id de la categoría"
          },
          {
            key: "category_name",
            label: "Nombre de la categoría"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }
        ];
      },
    },
    caption: {
      type: String,
      default: "Table",
    }
  },
  data() {
    return {
      deleteModal: false,
      category: {},
    }
  },
  methods: {
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} category - La categoria sobre el que se ha llamado al evento
     */
    botonEliminar(category) {
      this.category = category;
      this.deleteModal = true;
    },
    /**
     * Este evento de click, se ejecuta al hacer click en el boton de editar de la tabla acciones
     * te lleva a la página para editar el consejo
     * 
     * @param {number} id - El ID de la categoría sobre el que se ha llamado el evento editar
     */
    editCategory(id) {
      this.$router.push("/categoria/editCategoria/" + id);
    },
    /**
     * Este metodo eliminará la categoría, mandando por la URL el id
     * 
     * @param {number} id - El id de la categoría a eliminar
     */
    deleteCategory(id) {
      const token = localStorage.token;
      axios.delete(
        process.env.VUE_APP_URL + process.env.VUE_APP_URL_CATEGORIAS + id,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(() => {
        location.reload();
      })
      .catch((error) => {
        if(error.response) {
          if(error.response.status === 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
      this.deleteModal = false;
    },
  }
}
</script>